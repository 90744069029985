import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
	
        <h3>
          Stránka brzy v provozu!
        </h3>
	<p className="p-cl">
	  Kontaktujte nás na 
		<span> </span><a className="App-link"
          href = "mailto:richard.novotny@efinpro.cz"
          target="_blank"
          rel="noopener noreferrer">richard.novotny@efinpro.cz</a><span> </span>
	  nebo na 
		<span> </span><a className="App-link"
          href = "mailto:info@efinpro.cz"
          target="_blank"
          rel="noopener noreferrer">info@efinpro.cz</a>
	</p>
        
      </header>
    </div>
  );
}

export default App;
